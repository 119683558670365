<template>
    <div class="header-site" v-if="site.style">
        <div class="header-site__content">
            <div class="header-site__logo" @click="home" :style="site.style && site.style.logo ? `background-image: url(${ imageSrc(site.style.logo) })` : ''"></div>
            <div class="header-site__buttons" v-if="!$route.meta.hideButtons">
                <div>
                    <BaseButton class="header-site__buttons__cart" fill v-if="cart.products && cart.products.length" @click="$root.$emit('popupCartShow')">
                        <v-icon>mdi-cart</v-icon>&nbsp;{{ priceFormated(cart.full_price) }}
                    </BaseButton>
                </div>

                <v-menu transition="slide-y-transition" offset-y hide-on-scroll v-if="user.id">
                    <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-icon>mdi-account-circle</v-icon>
                        </div>
                    </template>
                    <v-list>
                        <v-list-item @click="$root.$emit('popupProfileShow')">
                            <v-list-item-title>Мои данные</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$router.push(`/${ site.code }/orders`)">
                            <v-list-item-title>Мои заказы</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="logout">
                            <v-list-item-title>Выйти</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <BaseButton class="header-site__buttons__auth" v-else @click="$root.$emit('popupLoginShow')">
                    <span>Войти</span>
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated } from '../../helpers';
    import { shortAddressFormat } from '../../helpers/user';

    import BaseButton from '../common/BaseButton';

    export default {
        name: 'Header',
        components: {
            BaseButton
        },
        data: () => ({
            imageSrc,
            priceFormated,
            shortAddressFormat
        }),
        computed: {
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('users', {
                user: state => state.user
            }),
            ...mapState('orders', {
                cart: state => state.cart
            })
        },
        mounted() {
            
        },
        methods: {
            home() {
                if(this.site.code) {
                    this.$router.push(`/${ this.site.code }`);
                } else {
                    this.$router.push('/');
                }
            },
            addAddress() {
                this.$root.$emit('popupAddressShow');
            },
            async setMainAddress(id) {
                await store.dispatch('userAddresses/setMain', { id });
                await store.dispatch('userAddresses/fetch');
            },
            logout() {
                store.dispatch('users/logout');
                this.home();
            }
        }
    }
</script>

<style lang="scss">
.header-site {
    width: 100%;
    height: 70px;
    position: sticky;
    top: 0;
    background-color: var(--header_bg);
    z-index: 10;

    &__content {
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        width: 200px; 
        height: 50px;
        // background-image: url('../../assets/logo-name-light.svg');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;

        @media screen and (max-width: 460px) {
            width: 120px;
            // background-image: url('../../assets/logo.svg');

            span {
                display: none;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 140px;

        &__cart {
            display: none;

            @media screen and (max-width: 1400px) {
                display: block;
            }
        }

        &__auth {
            display: block;

            @media screen and (max-width: 1400px) {
                display: none;
            }
        }

        & > div {
            margin-left: 20px;
        }

        .v-icon, .v-icon.v-icon {
            font-size: 40px;
            color: #ffffff;
        }
    }
}
</style>
<template>
    <div class="footer-site">
        <div class="footer-site__content">
            <div class="footer-site__link footer-site__link__left">
                <a href="/docs/Marketoria-agreement.pdf" target="_blank">Пользовательское соглашение</a>
            </div>
            <div class="footer-site__link footer-site__link__center" v-if="site.legal_information">
                <a :href="imageSrc(site.legal_information)" target="_blank">Правовая информация</a>
            </div>
            <div class="footer-site__link footer-site__link__right">
                <a href="/" target="_blank">Сайт работает на платформе Marketoria</a>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc } from '../../helpers';

    export default {
        name: 'Footer',
        components: {
        },
        data: () => ({
            imageSrc
        }),
        computed: {
            ...mapState('sites', {
                site: state => state.entity
            })
        },
        mounted() {
            // console.log(this.site);
        },
        methods: {
            home() {
                if(this.site.code) {
                    this.$router.push(`/${ this.site.code }`);
                } else {
                    this.$router.push('/');
                }
            },
            addAddress() {
                this.$root.$emit('popupAddressShow');
            },
            async setMainAddress(id) {
                await store.dispatch('userAddresses/setMain', { id });
                await store.dispatch('userAddresses/fetch');
            },
            logout() {
                store.dispatch('users/logout');
                this.home();
            }
        }
    }
</script>

<style lang="scss">
.footer-site {
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    z-index: 10;

    @media screen and (max-width: 550px) {
        height: 90px;
    }

    &__content {
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 550px) {
            flex-direction: column;
        }
    }

    &__link {
        width: 30%;
        color: #4F4F4F;
        cursor: pointer;

        a {
            color: #4F4F4F !important;
            text-decoration: none;
        }

        @media screen and (max-width: 550px) {
            width: 100%;
        }

        &__left {
            text-align: left;

            @media screen and (max-width: 550px) {
                text-align: center;
            }
        }
        &__center {
            text-align: center;

            @media screen and (max-width: 550px) {
                text-align: center;
            }
        }
        &__right {
            text-align: right;

            @media screen and (max-width: 550px) {
                text-align: center;
            }
        }
    }
}
</style>
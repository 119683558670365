import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.site.style)?_c('div',{staticClass:"header-site"},[_c('div',{staticClass:"header-site__content"},[_c('div',{staticClass:"header-site__logo",style:(_vm.site.style && _vm.site.style.logo ? `background-image: url(${ _vm.imageSrc(_vm.site.style.logo) })` : ''),on:{"click":_vm.home}}),(!_vm.$route.meta.hideButtons)?_c('div',{staticClass:"header-site__buttons"},[_c('div',[(_vm.cart.products && _vm.cart.products.length)?_c('BaseButton',{staticClass:"header-site__buttons__cart",attrs:{"fill":""},on:{"click":function($event){return _vm.$root.$emit('popupCartShow')}}},[_c(VIcon,[_vm._v("mdi-cart")]),_vm._v(" "+_vm._s(_vm.priceFormated(_vm.cart.full_price))+" ")],1):_vm._e()],1),(_vm.user.id)?_c(VMenu,{attrs:{"transition":"slide-y-transition","offset-y":"","hide-on-scroll":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VIcon,[_vm._v("mdi-account-circle")])],1)]}}],null,false,3363696758)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.$root.$emit('popupProfileShow')}}},[_c(VListItemTitle,[_vm._v("Мои данные")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$router.push(`/${ _vm.site.code }/orders`)}}},[_c(VListItemTitle,[_vm._v("Мои заказы")])],1),_c(VDivider),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemTitle,[_vm._v("Выйти")])],1)],1)],1):_c('BaseButton',{staticClass:"header-site__buttons__auth",on:{"click":function($event){return _vm.$root.$emit('popupLoginShow')}}},[_c('span',[_vm._v("Войти")])])],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
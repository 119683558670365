<template>
    <div>
        <Header />
        <div class="content">
            <slot />
        </div>
        <Footer />
    </div>
</template>

<script>
    import Header from '../components/site/Header';
    import Footer from '../components/site/Footer';

    export default {
        name: 'LayoutSite',
        components: {
            Header,
            Footer
        },
    }
</script>

<style lang="scss">
.content {
    width: 100%;
    padding: 20px 20px 0;
    min-height: calc(100vh - 120px);

    @media screen and (max-width: 460px) {
        padding: 10px 10px 0;
    }
    @media screen and (max-width: 550px) {
        min-height: calc(100vh - 160px);
    }
}
</style>